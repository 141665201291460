import { people } from "./family-videos";

export const events = [
  {
    goal: "2020-06-18T18:00:00+10:00",
    message:
      "So... I heard you're a social worker, even got a job these days eh.<br>I'm so proud of you and everything you have achieved, you've overcome so much and continue to excel!!",
    theme: "career",
    codeword: "purple-monkeys",
    tip: ["👩‍⚕️", "🆎", "🏥", "📖", "🔴", "⚪️", "🚑", "😷"],
  },
  {
    goal: "2020-06-21T15:00:00+10:00",
    message:
      "2 degrees (1 useful, 1 not so much), 6 (or 7) years later...<br> Congratulations, and I hope you're ready for your grad ceremony!",
    theme: "education",
    codeword: "sydney-uni-smells",
    tip: ["🏥", "📖", "👩‍🎓", "🎓", "🏫", "👩‍🏫", "👨‍🏫", "🤓"],
  },
  {
    goal: "2020-06-28T15:00:00+10:00",
    message: "Time to get creative. Let's use those paints you bought 😜",
    theme: "creativity",
    codeword: "I-TOLD-YOU-NOT-TO-BUY-A-MUG!",
    tip: ["🎨", "👩‍🎨", "👨‍🎨", "🖼", "🖌", "🖍", "🧘‍♀", "🏡"],
  },
  {
    goal: "2020-07-04T19:00:00+10:00",
    message:
      "We all love you ❣️...<br><br><br><br><br><br> p.s. click on someones face...",
    theme: "love and family",
    codeword: "ohana",
    extras: [
      {
        type: "video",
        data: people,
      },
    ],
    tip: ["👨‍👩‍👧‍👧", "👩‍👦", "👩‍👧", "👩‍👩‍👧‍👧", "👨‍👨‍👧", "👩‍👩‍👧", "👩‍👧‍👧", "👩‍👩‍👦", "👨‍👨‍👦‍👦", "👩‍👧‍👦", "👨‍👨‍👧‍👦"],
  },
];
