<script>
import Greeting from "./components/Greeting.svelte";
import Event from "./components/Event.svelte";

import { getTimeRemaining } from "./lib/date";
import {events} from "./constants/events";
import NoEvents from "./components/NoEvents.svelte";

const now = new Date();

const eventWithinAnHourFromNow = events.find(ev => {
  const differenceInSeconds = (Date.parse(now) - Date.parse(ev.goal))/1000;

  if (!differenceInSeconds || differenceInSeconds < 0) return;

  if (differenceInSeconds <= 3600) {
    return ev
  }
});

/**
 * Finds the next closest event in the future
*/
const closestEvent = events.reduce((prev, curr) => {
  // check for behind
  if ((Date.parse(curr.goal) - Date.parse(now)) <= 0) {
    return prev;
  }
  
  if (!prev) {
    return curr;
  }

  const diff = Date.parse(curr.goal) - Date.parse(now);
  const prevDiff = Date.parse(prev.goal) - Date.parse(now);
  
  if (diff < prevDiff) {
    return curr;
  }

  return prev;
}, null);

const event = eventWithinAnHourFromNow || closestEvent || null;
</script>

<main>
  <!-- <div class="child">
    <Greeting name="Nat" />
  </div> -->
  <div class="child">
    {#if event}
      <Event event={event}/>
    {:else}
      <NoEvents />
    {/if}
  </div>
</main>

<style>
	main {
		text-align: center;
		padding: 1em;
	}

  .child {
    margin-bottom: 2vw;
  }

	@media (min-width: 640px) {
		main {
			max-width: none;
		}
	}
</style>