export const getDevice = () => {
  const width = document.body.clientWidth;

  let device;

  // terrible hack, literally hate myself, but it works 🤷‍♂️
  if (width <= 479) {
    device = "mobile";
  } else {
    device = "desktop";
  }

  return device;
};
