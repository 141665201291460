export const people = [
  {
    name: "Michelle",
    photo: "assets/michelle.jpg",
    youtubeVideoId: "epin-WOwiz8",
  },
  {
    name: "Andre",
    photo: "assets/andre.png",
    youtubeVideoId: "G4DSnX8dj4Y",
  },
  {
    name: "Emma",
    photo: "assets/emma.jpg",
    youtubeVideoId: "W7Mqzd7I1iA",
  },
  {
    name: "Karl",
    photo: "assets/karl.png",
    youtubeVideoId: "dWIV-i0hKqE",
  },
  {
    name: "Sarah",
    photo: "assets/sarah.jpg",
    youtubeVideoId: "Skkiss2nvDA",
  },
  {
    name: "Kory",
    photo: "assets/kory.jpg",
    youtubeVideoId: "VW7x8IdE1Ck",
  },
];
