<script>
import Video from "./Video.svelte";
import Face from "./Face.svelte";
import Countdown from "./Countdown.svelte";
import Greeting from "./Greeting.svelte";
import Tip from "./Tip.svelte";

import { getTimeRemaining } from "../lib/date";

export let event;

const eventVideo = event.extras && event.extras.find(ex => ex.type === "video");

let personSelected;

const onClickHandler = (name) => {
  personSelected = eventVideo.data.find(d => d.name === name);
  
  // don't judge me, it works
  setTimeout(() => {
    document.body.querySelector(".video").scrollIntoView();
  }, 50);
}

let finished = getTimeRemaining(event.goal).total <= 0;

// check to see if finished
let interval = setInterval(() => {
  if (getTimeRemaining(event.goal).total <= 0) {
    finished = true;
    clearInterval(interval);
  }
}, 500);
</script>

<main>
  <div class="child">
    <Tip event={event} finished={finished}  />
  </div>
  <div class="child">
    <Countdown event={event} finished={finished}/>
  </div>
  {#if eventVideo && finished}
    <div class="child face-container">
      {#each eventVideo.data as person}
        <div class="face">
          <Face
            isSelected={personSelected && personSelected.name === person.name}
            name={person.name}
            photoURL={person.photo}
            onClickHandler={onClickHandler}
          />
        </div>
      {/each}
    </div>
    {#if personSelected && personSelected.youtubeVideoId}
      <div class="child video">
        <div class="person-selected">{personSelected.name}</div>
        <Video youtubeVideoId={personSelected.youtubeVideoId}/>
      </div>
    {/if}
  {/if}

</main>

<style>
  .child {
    margin-bottom: 2vw;
  }

  .person-selected {
    font-weight: 200;
    margin-bottom: 3vh;
  }

  .video {
    margin-top: 5vh;
  }

  .face-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .face {
    margin: 0.5vw;
  }
</style>