<script>
  import { getDevice } from "../lib/getDevice";

  import { onMount, onDestroy, afterUpdate } from "svelte";
  import { getTimeRemaining } from "../lib/date";

  export let event;
  export let finished;

  let total;
  let days;
  let hours;
  let minutes;
  let seconds;

  const update = () => {
    ({ total, days, hours, minutes, seconds } = getTimeRemaining(event.goal));
  }

  let preLoadedConfettiModule = false;

  const interval = setInterval(() => {
    // if within a minute of finishing, load the confetti module

    if (!finished) {
      update();
  
      if (!preLoadedConfettiModule && total < 30000) {
        console.log("pre-loading confetti module");
        // not really true but we aren't really building an enterprise app here :P
        preLoadedConfettiModule = true;
        import("canvas-confetti");
      }

      if (total <= 0) {
        clearInterval(interval);
      }
    }
  }, 1000);


  let erupting = false;
  let confettiErupted = false;

  const startEruptionIfNeeded = async () => {
    if (finished && !erupting && !confettiErupted) {
      erupting = true;
      const { default: confetti } = await import("canvas-confetti");
      // confetti();

      const duration = 5000;
      const end = Date.now() + duration;

      const device = getDevice();

      // less particles on a non-desktop device
      const particleCount = device === "mobile" ? 4 : 8;

      (function frame() {
        // launch a few confetti from the left edge
        confetti({
          particleCount: particleCount,
          angle: 60,
          spread: 55,
          origin: { x: 0 }
        });
        // and launch a few from the right edge
        confetti({
          particleCount: particleCount,
          angle: 120,
          spread: 55,
          origin: { x: 1 }
        });

        // keep going until we are out of time
        if (Date.now() < end) {
          // setTimeout(frame, device === "mobile" ? 45 : 10);
          requestAnimationFrame(frame);
        } else {
          erupting = false;
          confettiErupted = true;
        }
      }());
    }
  }

  update();

  onDestroy(() => clearInterval(interval));

  afterUpdate(() => {
    startEruptionIfNeeded();
  })
</script>

<main>
  {#if !finished}
    <div class="counter">
      {#if days > 0}
        <div class="group">
          <span class="value">{days}</span>
          <span class="unit">days</span>
        </div>
      {/if}

      {#if hours > 0}
        <div class="group">
          <span class="value">{hours}</span>
          <span class="unit">hours</span>
        </div>
      {/if}

      {#if minutes > 0}
        <div class="group">
          <span class="value">{minutes}</span>
          <span class="unit">minutes</span>
        </div>
      {/if}

      {#if seconds > 0}
        <div class="group">
          <span class="value">{seconds}</span>
          <span class="unit">seconds</span>
        </div>
      {/if}
    </div>
  {:else}
    <p class="message">{@html event.message}</p>
  {/if}
</main>

<style>
  .group {
    display: flex;
    align-items: baseline;
    margin-right: 1vw;
  }

  .value {
    font-size: 5vw;
  }

  .unit {
    font-size: 1.25vw;
    font-style: italic;
    font-weight: 100;
  }

  .counter {
    /* font-size: 4vw; */
    display: flex;
    /* align-items: center; */
    justify-content: center;
  }

  .message {
    font-weight: 200;
  }
</style>