<script>
  import { onMount } from "svelte";
  import { getDevice } from "../lib/getDevice";

  const TIMER_NOT_FINISHED_EMOJI = "🤔";
  const TIMER_FINISHED_EMOJI = "😜";

  export let event;
  export let finished;

  let canvasElement;

  const device = getDevice();

  onMount(() => {
    // half of the screen
    const size = Math.ceil(document.body.clientWidth * (device === "desktop" ? 0.20 : 0.5));

    const ctx = canvasElement.getContext("2d");

    // set style
    canvasElement.style.width = `${size}px`;
    canvasElement.style.height = `${size}px`;

    const scale = window.devicePixelRatio; // Change to 1 on retina screens to see blurry canvas.
    canvasElement.width = Math.floor(size * scale);
    canvasElement.height = Math.floor(size * scale);

    const emojiSizeFactor = 8;
    
    ctx.scale(scale, scale);

    // sets the size of the emoji
    ctx.font = `${size / emojiSizeFactor}px serif`;
  
    // ensures that if we place an emoji at x,y (10,20) it will place the MIDDLe of the emoji at that point
    ctx.textAlign = "center";

    const radius = (size / 2) - (size / (emojiSizeFactor));
    const step = (2 * Math.PI) / event.tip.length;
    let angle = 0;

    const yOffset = (size / emojiSizeFactor / 2)
    
    setInterval(() => {
      ctx.clearRect(0, 0, size, size);

      // add different emojis to showcase the countdown finish state
      if (finished) { 
        ctx.fillText(TIMER_FINISHED_EMOJI, size /2 ,(size /2) + yOffset);
      } else {
        ctx.fillText(TIMER_NOT_FINISHED_EMOJI, size /2 ,(size /2) + yOffset);
      }

      event.tip.forEach((item) => {
        const x = radius * Math.cos(angle) + size / 2;
        const y = radius * Math.sin(angle) + size / 2 + yOffset;
        ctx.fillText(item, x, y);
        angle += step;
      });
      angle += step / event.tip.length / 8;
    }, 30);
  })

</script>

<main>
  <div>
    <canvas bind:this={canvasElement}></canvas>
    {#if finished}
      <p class="tip">
        theme: {event.theme}
      </p>
      <p class="tip">
        codeword: {event.codeword}
      </p>
    {/if}
  </div>
</main>

<style>
  .tip {
    font-style:italic;
    font-weight: 100;
  }
</style>