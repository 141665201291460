<script>
  export let youtubeVideoId;
</script>

<main>
  <iframe
    title="video"
    class="embedded-video-16-9"
    src="https://www.youtube.com/embed/{youtubeVideoId}"
    frameborder="0"
    modestbranding="1"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  >
  </iframe>
</main>

<style>
  .embedded-video-16-9 {
    width:  90vw;
    height: 50.625vw; /* 90*9/16 */
  }

  @media (min-width: 893px) {
    .embedded-video-16-9 {
      width: 45vw;
      height: 25.3125vw; /* 45*9/16 */
    }
  }
</style>