<script>
  export let name;
  export let photoURL;
  export let onClickHandler;
  export let isSelected;

  const clickHandler = () => {
    onClickHandler(name)
  }
</script>

<main on:click={clickHandler}>
  <img class="face-image {isSelected && "selected"}" src={photoURL} alt="Photo of {name}" />
</main>

<style>

  .face-image {
    width: 30vw;
    height: 30vw;
    object-fit: cover;
    border-radius: 2px;
  }

  @media (min-width: 893px) {
    .face-image {
      width: 15vw;
      height: 15vw;
      object-fit: cover;
      border-radius: 2px;
    }
  }

  .face-image:hover {
    opacity: 0.5;
  }

  .selected {
    opacity: 0.5
  }

</style>